export default function useMetadata(metadata: any) {
	const publication = usePublication();
	const audience = ref<string>("");
	const ressorts = ref<any[]>([]);
	if (metadata) {
		let audienceIdentifier = `audience-${publication}`;
		audience.value = metadata[audienceIdentifier];
		const ressortsIdentifier = `ressorts-${publication}`;
		ressorts.value = metadata[ressortsIdentifier]?.values;
	}
	return {
		audience,
		ressorts,
	};
}
